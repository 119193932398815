import React, { useMemo } from "react";
import { Typography, Container, Box } from "@material-ui/core";
import clsx from "clsx";
import LandingPageNavigation from "../LandingPageNavigation";
import "./styles.scss";
import {
  getShouldShowAnnualTravelCreditBanner,
  Icon,
  LandingBenefits,
  PremierCollectionBenefits,
  IconName,
} from "halifax";
import {
  CallState,
  RewardsAccount,
  Tenant,
  WalletDetailsResponse,
} from "redmond";
import * as textConstants from "./textConstants";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CASH_VALUEPROP_EXPERIMENT,
  CASH_VALUEPROP_VARIANTS,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  NOT_SELF_FUNDED,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  SELF_FUNDED,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  useExperiments,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
} from "../../context/experiments";
import { RecentlyViewedAndSearched } from "../RecentlyViewedAndSearched";
import { RecentlyViewedAndSearchedProps } from "../Body/recently-viewed-and-searched-helpers";
import {
  CORP_HOMEPAGE_TITLE,
  CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT,
  isCorpTenant,
  useUserContext,
} from "@capone/common";
import { fetchTravelWalletDetails } from "../../api/v1/takeovers/fetchTravelWalletDetails";
import { HomepageAnnualTravelCredit } from "../HomepageAnnualTravelCredit";
import { HomepageTravelSale } from "../HomepageTravelSale/component";
import config from "../../utils/config";
import { useExperimentsById } from "@capone/experiments";

export enum LandingPageType {
  FLIGHTS = "flights",
  HOTELS = "hotels",
  STAYS = "stays",
  CARS = "cars",
  PREMIUM_STAYS = "premium-stays",
  PACKAGES = "packages",
}

export interface MobileLandingPageProps {
  type: LandingPageType;
  rewardsAccounts: RewardsAccount[];
  recentlyViewedAndSearchedProps?: RecentlyViewedAndSearchedProps | null;
  fetchRewardsAccountsCallState: CallState;
}

export const MobileLandingPage = ({
  type,
  rewardsAccounts,
  recentlyViewedAndSearchedProps,
  fetchRewardsAccountsCallState,
}: MobileLandingPageProps) => {
  const { sessionInfo } = useUserContext(config.TENANT);
  const [largestValueAccount, setLargestValueAccount] = React.useState<
    RewardsAccount | undefined
  >(undefined);
  const [walletDetails, setWalletDetails] =
    React.useState<WalletDetailsResponse | null>(null);
  const expState = useExperiments();

  const cashValuePropVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CASH_VALUEPROP_EXPERIMENT,
    CASH_VALUEPROP_VARIANTS
  );

  const crossSellExperiment = getExperimentVariant(
    expState.experiments,
    HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT
  );

  const isCrossSellExperiment = React.useMemo(
    () => crossSellExperiment === AVAILABLE,
    [crossSellExperiment]
  );

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  React.useEffect(() => {
    const earnMultiplier = (account: RewardsAccount) =>
      account.earn.hotelsMultiplier ?? -1;
    setLargestValueAccount(
      rewardsAccounts.sort((prev, current) => {
        return earnMultiplier(current) - earnMultiplier(prev);
      })[0]
    );
  }, [rewardsAccounts]);

  React.useEffect(() => {
    fetchTravelWalletDetails()
      .then((response) => setWalletDetails(response))
      .catch(() => null);
  }, []);

  const hasOnlyCashCards = React.useMemo(
    () =>
      rewardsAccounts?.length > 0 &&
      rewardsAccounts.every(
        (account) => account.rewardsBalance.currency.toLowerCase() === "cash"
      ),
    [rewardsAccounts]
  );

  const benefitsVariant = React.useMemo(() => {
    switch (cashValuePropVariant) {
      case "control":
      default:
        return [];
      case "cash-valueprop-a":
        return textConstants.CASH_CARD_BENEFITS.VARIANT_A;
      case "cash-valueprop-b":
        return textConstants.CASH_CARD_BENEFITS.VARIANT_B;
      case "cash-valueprop-c":
        return textConstants.CASH_CARD_BENEFITS.VARIANT_C;
    }
  }, [cashValuePropVariant]);

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isMobileHomeScreenVariant3 =
    mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3;

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const { shouldShowVentureBanner, shouldShowVentureXBanner } =
    getShouldShowAnnualTravelCreditBanner(
      rewardsAccounts,
      walletDetails?.creditBreakdown
    );

  const showAnnualVentureXTravelCredit =
    shouldShowVentureXBanner &&
    isAnnualTravelCreditsExperiment &&
    !!sessionInfo?.userInfo.firstName;

  const showAnnualVentureTravelCredit =
    shouldShowVentureBanner &&
    isAnnualTravelCreditsExperiment &&
    !!sessionInfo?.userInfo.firstName;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const VRForPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForPremiumCardHoldersEnabled = useMemo(
    () => VRForPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const VRForNonPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForNonPremiumCardHoldersEnabled = useMemo(
    () => VRForNonPremiumCardHolders === AVAILABLE,
    [VRForNonPremiumCardHolders]
  );

  const showDBCCustomHeader =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const getMobileLandingTitle = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return (
        <span>
          {showDBCCustomHeader
            ? CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT
            : CORP_HOMEPAGE_TITLE}
        </span>
      );
    } else if (
      isPackagesExperiment &&
      type === LandingPageType.PACKAGES &&
      largestValueAccount
    ) {
      return textConstants.PACKAGES_TITLE;
    } else if (
      !(
        type === LandingPageType.PREMIUM_STAYS &&
        (isLCForNonPremiumCardHoldersEnabled ||
          isLCForPremiumCardHoldersEnabled)
      )
    ) {
      return isMobileHomeScreenVariant3 ? (
        textConstants.getRedesignVariant3Title(
          type,
          fetchRewardsAccountsCallState,
          largestValueAccount
        )
      ) : (
        <>
          <span>
            {type === LandingPageType.PREMIUM_STAYS
              ? textConstants.PREMIER_COLLECTION_TITLE_FIRST_HALF_BOLD
              : textConstants.TITLE_FIRST_HALF_BOLD}
          </span>
          {type === LandingPageType.PREMIUM_STAYS
            ? textConstants.PREMIER_COLLECTION_TITLE_SECOND_HALF
            : textConstants.TITLE_SECOND_HALF}
        </>
      );
    } else if (type === LandingPageType.PREMIUM_STAYS) {
      if (
        isLCForNonPremiumCardHoldersEnabled &&
        !isVRForNonPremiumCardHoldersEnabled
      ) {
        return textConstants.LC_NON_PREMIUM_TITLE;
      } else if (
        isLCForPremiumCardHoldersEnabled ||
        isVRForNonPremiumCardHoldersEnabled
      ) {
        return textConstants.LC_PREMIUM_TITLE;
      }
    }
    return (
      <>
        <span>{textConstants.TITLE_FIRST_HALF_BOLD}</span>
        {textConstants.TITLE_SECOND_HALF}
      </>
    );
  };

  return (
    <>
      <Container
        className={clsx("landing-page", type, config.TENANT, {
          "redesign-v3-enabled": isMobileHomeScreenVariant3,
          "homepage-cross-sell": isCrossSellExperiment,
          "only-lifestyle-collection":
            type === LandingPageType.PREMIUM_STAYS &&
            isLCForNonPremiumCardHoldersEnabled &&
            !isVRForNonPremiumCardHoldersEnabled,
          "with-lifestyle-collection":
            type === LandingPageType.PREMIUM_STAYS &&
            isLCForPremiumCardHoldersEnabled,
          "with-vacation-rentals":
            type === LandingPageType.PREMIUM_STAYS &&
            (isVRForPremiumCardHoldersEnabled ||
              isVRForNonPremiumCardHoldersEnabled),
          "show-travel-sale":
            type !== LandingPageType.PREMIUM_STAYS && isTravelSaleEnabled,
        })}
      >
        <Box className="content">
          <Typography variant="h2" className="search-title">
            {getMobileLandingTitle(config.TENANT)}
          </Typography>
          <Typography variant="body1" className="search-subtitle">
            {type === LandingPageType.PREMIUM_STAYS &&
              isVRForNonPremiumCardHoldersEnabled &&
              textConstants.VR_NON_PREMIUM_SUBTITLE}
            {type === LandingPageType.PREMIUM_STAYS &&
              isLCForNonPremiumCardHoldersEnabled &&
              !isVRForNonPremiumCardHoldersEnabled &&
              textConstants.LC_NON_PREMIUM_SUBTITLE}
            {type === LandingPageType.PREMIUM_STAYS &&
              isLCForPremiumCardHoldersEnabled &&
              textConstants.LC_PREMIUM_SUBTITLE}

            {!(
              (type === LandingPageType.PREMIUM_STAYS &&
                (isLCForNonPremiumCardHoldersEnabled ||
                  isLCForPremiumCardHoldersEnabled)) ||
              (type === LandingPageType.PACKAGES && isPackagesExperiment)
            ) &&
              (isMobileHomeScreenVariant3
                ? textConstants.getRedesignVariant3Subtitle(
                    type,
                    fetchRewardsAccountsCallState
                  )
                : textConstants.SUBTITLE)}

            {type === LandingPageType.PACKAGES &&
              isPackagesExperiment &&
              largestValueAccount &&
              textConstants.PACKAGES_SUBTITLE(largestValueAccount)}
          </Typography>

          <LandingPageNavigation type={type} />
        </Box>
        {largestValueAccount &&
          type === LandingPageType.PREMIUM_STAYS &&
          (isVRForPremiumCardHoldersEnabled ||
            isVRForNonPremiumCardHoldersEnabled) && (
            <Box className="view-travel-benefits-wrapper">
              <Box className="view-travel-benefits-text-and-icon-wrapper">
                <Icon name={IconName.FilledGiftIcon} />
                <Typography className="view-travel-benefits-text">
                  View my travel benefits
                </Typography>
              </Box>
              <Icon name={IconName.Dropdown} />
            </Box>
          )}
      </Container>

      {(showAnnualVentureXTravelCredit || showAnnualVentureTravelCredit) &&
        walletDetails && (
          <HomepageAnnualTravelCredit
            firstName={sessionInfo.userInfo.firstName}
            rewardsAccounts={rewardsAccounts}
            walletDetails={walletDetails}
            isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
            isMobile
            showBanner={type !== LandingPageType.PREMIUM_STAYS}
            negativeMargin={!isTravelSaleEnabled}
          />
        )}
      {type !== LandingPageType.PREMIUM_STAYS && isTravelSaleEnabled && (
        <HomepageTravelSale
          active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
          offers={walletDetails?.offers || []}
          negativeMargin={
            !(showAnnualVentureXTravelCredit || showAnnualVentureTravelCredit)
          }
        />
      )}
      {recentlyViewedAndSearchedProps &&
        type !== LandingPageType.PREMIUM_STAYS && (
          <RecentlyViewedAndSearched
            {...recentlyViewedAndSearchedProps}
            negativeMargin={
              !(
                showAnnualVentureXTravelCredit || showAnnualVentureTravelCredit
              ) && !isTravelSaleEnabled
            }
          />
        )}
      {cashValuePropVariant !== "control" && hasOnlyCashCards && (
        <LandingBenefits
          title={textConstants.CASH_CARD_BENEFITS_TITLE(
            rewardsAccounts?.[0]?.productDisplayName
          )}
          benefits={benefitsVariant}
        />
      )}
      {type === LandingPageType.PREMIUM_STAYS && largestValueAccount && (
        <PremierCollectionBenefits
          largestValueAccount={largestValueAccount}
          isMobile
          variant={(() => {
            if (isLCForNonPremiumCardHoldersEnabled)
              return "only-lifestyle-collection";
            if (isLCForPremiumCardHoldersEnabled)
              return "with-lifestyle-collection";
            return "default";
          })()}
          rewardsAccounts={rewardsAccounts}
          includesVacationRentals={
            isVRForPremiumCardHoldersEnabled ||
            isVRForNonPremiumCardHoldersEnabled
          }
        />
      )}
    </>
  );
};
