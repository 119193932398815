import React, { useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import {
  getShouldShowAnnualTravelCreditBanner,
  useDeviceTypes,
  PostBookingOfferModal,
  UserPreferencesBanner,
  generateUserId,
} from "halifax";
import {
  Switch,
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  Link,
} from "react-router-dom";
import {
  LandingScreen,
  LaunchedApplicationProperties,
  LAUNCHED_APPLICATION,
  ProductFeedLoadingProperties,
  PRODUCT_FEED_LOADING,
  RewardsAccount,
  WalletDetailsResponse,
  CallState,
  StatementCreditDetail,
} from "redmond";
import {
  PATH_CORP_FAQ,
  useUserContext,
  isCaponeTenant,
  deleteCookie,
  setCookie,
  isCorpTenant,
} from "@capone/common";
import CarsModule from "../CarsModule";
import ExchangeModule from "../ExchangeModule";
import FlightsModule from "../FlightsModule";
import HotelsModule from "../HotelsModule";
import Maintenance from "../Maintenance/maintenance";
import { MobileLandingPage, LandingPageType } from "../MobileLandingPage";
import TermsModule from "../TermsModule";
import TravelWalletModule from "../TravelWalletModule";
import TripsModule from "../TripsModule";
import { trackEvent } from "../../api/v1/trackEvent";
import {
  addTrackingProperties,
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  STAYS_HOMEPAGE,
  CARS_MAINTENANCE,
  CARS_ONLY,
  CONTROL,
  CORPORATE_PORTAL_EXPERIMENT,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  FLIGHTS_MAINTENANCE,
  FLIGHTS_ONLY,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_CROSS_SELL_V3,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT,
  HOTELS_MAINTENANCE,
  HOTELS_ONLY,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  MAINTENANCE,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  NOT_SELF_FUNDED,
  ONE_PORTAL_VARIANT,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  POST_BOOKING_OFFER_EXPERIMENT,
  POST_BOOKING_OFFER_EXPERIMENT_REWARDS,
  PREMIER_COLLECTION_EXPERIMENT,
  PREMIUM_STAYS_ONLY,
  RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
  RECENTLY_VIEWED_MOBILE_P0_VARIANTS,
  RECENTLY_VIEWED_V2_CARS,
  RECENTLY_VIEWED_V2_FLIGHTS,
  RESTRICT_FUNNELS,
  RESTRICT_FUNNELS_VARIANTS,
  SELF_FUNDED,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  TRIPS_MAINTENANCE,
  useExperiments,
} from "../../context/experiments";
import {
  USER_SOURCE_KEY,
  USER_MEDIUM_KEY,
  useUtmParams,
} from "../../context/userSource";
import useStyles from "./styles";
import {
  PATH_FLIGHTS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS,
  PATH_HOTELS_SEARCH,
  PATH_CARS,
  PATH_CARS_SEARCH,
  PATH_TRIPS,
  PATH_TERMS,
  PATH_TERMS_OF_SERVICE,
  PATH_SITE_TERMS_OF_USE,
  PATH_EXCHANGE,
  PATH_TRAVEL_WALLET,
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_PREMIER_COLLECTION,
  DISPLAY_CROSS_SELL_AND_RV_PATHS,
  PATH_TRAVEL_SALE,
  PATH_VACATION_RENTALS_SEARCH,
  PATH_HOME,
  PATH_CORP_ACCESS_DENIED,
  PATH_CORP_ACCESS_DENIED_NOT_BETA,
  PATH_CORP_NOT_LIVE,
  PATH_CUSTOMER_PROFILE,
  PATH_PACKAGES,
  PATH_PACKAGES_SEARCH,
  PATH_STAYS,
  PATH_STAYS_SEARCH,
} from "../../utils/urlPaths";

import "./styles.scss";
import PremierCollectionModule from "../PremierCollectionModule";
import { fetchTravelWalletDetails } from "../../api/v1/takeovers/fetchTravelWalletDetails";
import { CorporateSwitchModal } from "./components/CorporateSwitchModal";
import { HomepageHotelCrossSell } from "../HomepageHotelCrossSell";
import { CrossSellProps, fetchCrossSellInfoV3 } from "./cross-sell-helpers";
import { RecentlyViewedAndSearched } from "../RecentlyViewedAndSearched";
import {
  fetchRecentlyViewedAndSearched,
  RecentlyViewedAndSearchedProps,
} from "./recently-viewed-and-searched-helpers";
import { HomepageAnnualTravelCredit } from "../HomepageAnnualTravelCredit";
import config from "../../utils/config";
import { HomepageTravelSale } from "../HomepageTravelSale/component";
import getPostBookingOffer from "../../api/v1/post-booking-ancillary-offer/getPostBookingOffer";
import dayjs from "dayjs";
import {
  AccessDenied,
  AccessDeniedNotInBeta,
  PortalNotLive,
} from "../capone-corporate";
import CustomerProfileModule from "../CustomerProfileModule";
import fetchUserHotelPreferences from "../../api/v1/user/fetchUserHotelPreferences";
import fetchUserFlightPreferences from "../../api/v1/user/fetchUserFlightPreferences";
import { getCookie } from "@capone/common";
import { listPaymentMethods } from "../../api/v1/payment-methods/listPaymentMethods";
import PackagesModule from "../PackagesModule";

interface IBodyProps {
  language: string;
  className?: string;
  isFirstSession: boolean;
  launchEventSent: boolean;
  setLaunchEventSent: (val: boolean) => void;
  rewardsAccounts: RewardsAccount[];
  fetchRewardsAccountsCallState: CallState;
  pathname: string;
  notAuthenticated?: boolean;
}

const Body = (props: IBodyProps) => {
  const {
    language,
    className,
    isFirstSession,
    launchEventSent,
    setLaunchEventSent,
    rewardsAccounts,
    fetchRewardsAccountsCallState,
    pathname,
    notAuthenticated,
  } = props;
  const { sessionInfo, isBusinessEligible } = useUserContext(config.TENANT);
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const classes = useStyles();
  const utmParams = useUtmParams();
  const history = useHistory();
  const [fetchedWalletDetails, setFetchedWalletDetails] = useState(false);
  const [walletDetails, setWalletDetails] =
    useState<WalletDetailsResponse | null>(null);
  const [crossSellProps, setCrossSellProps] = useState<CrossSellProps | null>(
    null
  );
  const [crossSellLoaded, setCrossSellLoaded] = useState<boolean>(false);
  const [recentlyViewedAndSearchedProps, setRecentlyViewedAndSearchedProps] =
    useState<RecentlyViewedAndSearchedProps | null>(null);
  const [recentlyViewedLoaded, setRecentlyViewedLoaded] =
    useState<boolean>(false);
  const [userHasNotSetHotelPreferences, setUserHasNotSetHotelPreferences] =
    useState<boolean>(false);
  const [userHasNotSetFlightPreferences, setUserHasNotSetFlightPreferences] =
    useState<boolean>(false);
  const [showCrossSellV3UI, setShowCrossSellV3UI] = useState<boolean>(false);

  const isInMaintenance =
    getExperimentVariant(expState.experiments, MAINTENANCE) === AVAILABLE;
  const flightsMaintenance =
    getExperimentVariant(expState.experiments, FLIGHTS_MAINTENANCE) ===
    AVAILABLE;
  const hotelsMaintenance =
    getExperimentVariant(expState.experiments, HOTELS_MAINTENANCE) ===
    AVAILABLE;
  const carsMaintenance =
    getExperimentVariant(expState.experiments, CARS_MAINTENANCE) === AVAILABLE;
  const tripsMaintenance =
    getExperimentVariant(expState.experiments, TRIPS_MAINTENANCE) === AVAILABLE;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isHotelCrossSellExperiment =
    getExperimentVariant(
      expState.experiments,
      HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT
    ) === AVAILABLE;

  const isRecentlyViewedV1Experiment =
    getExperimentVariant(expState.experiments, RECENTLY_VIEWED_MASTER_V1) ===
    AVAILABLE;

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
    RECENTLY_VIEWED_DESKTOP_P0_VARIANTS
  );

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
    RECENTLY_VIEWED_MOBILE_P0_VARIANTS
  );
  const isRecentlyViewedFlightsV2 =
    getExperimentVariant(expState.experiments, RECENTLY_VIEWED_V2_FLIGHTS) ===
    AVAILABLE;

  const isRecentlyViewedCarsV2 =
    getExperimentVariant(expState.experiments, RECENTLY_VIEWED_V2_CARS) ===
    AVAILABLE;

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const isOnePortalExperimentEnabled =
    getExperimentVariant(expState.experiments, CORPORATE_PORTAL_EXPERIMENT) ===
    ONE_PORTAL_VARIANT;

  const isPostBookingOffersAvailable = getExperimentVariant(
    expState.experiments,
    POST_BOOKING_OFFER_EXPERIMENT
  ).startsWith(AVAILABLE);

  const showPostBookingPayByRewardsMerch =
    getExperimentVariant(
      expState.experiments,
      POST_BOOKING_OFFER_EXPERIMENT_REWARDS
    ) === "available-with-merch";

  const isDisruptionAirCopyAvailable = getExperimentVariant(
    expState.experiments,
    POST_BOOKING_OFFER_EXPERIMENT
  ).startsWith(AVAILABLE);

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  const isHotelCrossSellV3Enabled =
    getExperimentVariantCustomVariants(
      expState.experiments,
      HOTEL_CROSS_SELL_V3,
      HOTEL_CROSS_SELL_V3_VARIANTS
    ) !== CONTROL;

  const { shouldShowVentureBanner, shouldShowVentureXBanner } =
    getShouldShowAnnualTravelCreditBanner(
      rewardsAccounts,
      walletDetails?.creditBreakdown
    );

  const showAnnualTravelCredit =
    isAnnualTravelCreditsExperiment &&
    !!walletDetails &&
    (shouldShowVentureBanner || shouldShowVentureXBanner) &&
    !!sessionInfo?.userInfo.firstName;

  const restrictFunnelVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    RESTRICT_FUNNELS,
    RESTRICT_FUNNELS_VARIANTS
  );

  const staysHomepageEnabled =
    getExperimentVariant(expState.experiments, STAYS_HOMEPAGE) === AVAILABLE;

  const shouldRestrictFunnels = restrictFunnelVariant != CONTROL;

  const showCarsFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == CARS_ONLY;
  const showFlightsFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == FLIGHTS_ONLY;
  const showHotelsFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == HOTELS_ONLY;
  const showPremiumStaysFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == PREMIUM_STAYS_ONLY;

  const getLandingPageType = () => {
    switch (restrictFunnelVariant) {
      case CARS_ONLY:
        return LandingPageType.CARS;
      case FLIGHTS_ONLY:
        return LandingPageType.FLIGHTS;
      case HOTELS_ONLY:
        return LandingPageType.HOTELS;
      case PREMIUM_STAYS_ONLY:
        return LandingPageType.PREMIUM_STAYS;
      case CONTROL:
      default:
        return LandingPageType.HOTELS;
    }
  };

  const getRedirectPath = () => {
    switch (restrictFunnelVariant) {
      case CARS_ONLY:
        return PATH_CARS;
      case FLIGHTS_ONLY:
        return PATH_FLIGHTS;
      case HOTELS_ONLY:
        return `${PATH_HOTELS}${window.location.search}`;
      case PREMIUM_STAYS_ONLY:
        return PATH_PREMIER_COLLECTION;
      case CONTROL:
      default:
        return `${PATH_HOTELS}${window.location.search}`;
    }
  };

  useEffect(() => {
    setShowCrossSellV3UI(
      isHotelCrossSellV3Enabled && !!crossSellProps?.tysonsOffer
    );
  }, [crossSellProps]);

  useEffect(() => {
    if (!isFirstSession && !launchEventSent && sessionInfo?.csrfToken) {
      const fetchWalletDetails = async () => {
        const walletDetails = await fetchTravelWalletDetails().catch(
          () => null
        );
        setWalletDetails(walletDetails);
        setFetchedWalletDetails(true);
      };
      fetchWalletDetails();
    }
  }, [sessionInfo, isFirstSession, launchEventSent, setLaunchEventSent]);

  useEffect(() => {
    const doLaunch = async () => {
      if (
        !isFirstSession &&
        !launchEventSent &&
        sessionInfo?.csrfToken &&
        fetchedWalletDetails &&
        expState.trackingProperties
      ) {
        let account = null;
        let properties: LaunchedApplicationProperties = {
          landing_screen: getLandingScreen(),
          url: window.location.pathname,
          rewards_accounts: "",
          has_credits: !!walletDetails?.credit?.amount?.amount,
          credit_balance: walletDetails?.credit?.amount?.amount || 0,
          vx_statement_credit_balance:
            walletDetails?.creditBreakdown
              ?.filter((b) => b.CreditDetail === "Statement")
              .reduce(
                (prev, curr) =>
                  prev + (curr as StatementCreditDetail).usableAmount.amount,
                0
              ) || 0,
        };

        if (utmParams) {
          const { userSource, userMedium } = utmParams;
          properties[USER_SOURCE_KEY] =
            userSource || sessionStorage.getItem(USER_SOURCE_KEY);
          properties[USER_MEDIUM_KEY] =
            userMedium || sessionStorage.getItem(USER_MEDIUM_KEY);
          window?.DD_LOGS?.logger?.info(
            `Launched application event - utm_source ${properties[USER_SOURCE_KEY]} - utm_medium ${properties[USER_MEDIUM_KEY]}`
          );
        }

        if (rewardsAccounts.length > 0) {
          properties["rewards_accounts"] = rewardsAccounts
            .map((r) => r.productDisplayName)
            .join(",");
          const earnMultiplier = (account: RewardsAccount) =>
            account.earn.hotelsMultiplier ?? -1;
          account = rewardsAccounts.sort((prev, current) => {
            return earnMultiplier(current) - earnMultiplier(prev);
          })[0];
        }
        properties = addTrackingProperties(
          expState.trackingProperties,
          properties
        );

        // Delete non-root session cookies to prevent inconsistent behavior on different paths
        deleteCookie("browser_session_id", "/hotels/availability");
        deleteCookie("browser_session_id", "/hotels/shop");
        deleteCookie("browser_session_id", "/hotels/book");
        deleteCookie("browser_session_id", "/flights/shop");
        deleteCookie("browser_session_id", "/cars/availability");

        const sessionId = getCookie("browser_session_id");
        if (!sessionId) {
          setCookie("browser_session_id", generateUserId());
        }

        let hasPaymentMethods = false;

        try {
          const payments = await listPaymentMethods();
          hasPaymentMethods = payments.length > 0;
        } catch (e) {
          console.error(e);
        }

        trackEvent({
          eventName: LAUNCHED_APPLICATION,
          properties: {
            ...properties,
            account_use_type: account?.accountUseType,
            customer_account_role: account?.customerAccountRole,
            account_allow_rewards_redemption: account?.allowRewardsRedemption,
            card_on_file: hasPaymentMethods,
          },
        });

        setLaunchEventSent(true);
      }
    };

    doLaunch();
  }, [
    sessionInfo,
    expState,
    isFirstSession,
    launchEventSent,
    utmParams,
    setLaunchEventSent,
    rewardsAccounts,
    fetchedWalletDetails,
  ]);

  useEffect(() => {
    const getUserPrefs = async () => {
      const userHotelPreferences = await fetchUserHotelPreferences();

      const hasSetHotelPreferences =
        !!userHotelPreferences?.amenities.length ||
        !!userHotelPreferences?.starRatings.length ||
        !!userHotelPreferences?.freeCancellationOnly;

      setUserHasNotSetHotelPreferences(!hasSetHotelPreferences);

      const userFlightPreferences = await fetchUserFlightPreferences();

      const hasSetFlightPreferences =
        !!userFlightPreferences.airlines.length ||
        !!userFlightPreferences.homeAirport ||
        userFlightPreferences.nonStopOnly ||
        Object.values(userFlightPreferences.fareClasses).some(
          (val) => val == true
        );

      setUserHasNotSetFlightPreferences(!hasSetFlightPreferences);
    };

    if (matchesMobile && isCustomerProfileExperiment) {
      getUserPrefs();
    }
  }, [matchesMobile, isCustomerProfileExperiment, pathname]);

  const displayCrossSellRV = React.useMemo(() => {
    return DISPLAY_CROSS_SELL_AND_RV_PATHS.includes(pathname);
  }, [pathname]);

  const showUserHotelPreferencesBanner =
    isCustomerProfileExperiment &&
    matchesMobile &&
    userHasNotSetHotelPreferences;

  const showUserFlightPreferencesBanner =
    isCustomerProfileExperiment &&
    matchesMobile &&
    userHasNotSetFlightPreferences;

  useEffect(() => {
    if (
      displayCrossSellRV &&
      rewardsAccounts.length > 0 &&
      isHotelCrossSellExperiment
    ) {
      const earnMultiplier = (account: RewardsAccount) =>
        account.earn.hotelsMultiplier ?? -1;
      const account = rewardsAccounts.sort((prev, current) => {
        return earnMultiplier(current) - earnMultiplier(prev);
      })[0];
      const fetchCrossSell = async () => {
        const props: CrossSellProps | false = await fetchCrossSellInfoV3(
          account,
          isHotelCrossSellV3Enabled
        );
        if (props) {
          setCrossSellProps(props);
          setCrossSellLoaded(true);
        }
      };
      const properties: ProductFeedLoadingProperties = {
        feed_placement: "homepage",
        feed_type: "cross_sell_hotels",
      };
      trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
      fetchCrossSell();
    }
  }, [rewardsAccounts, isHotelCrossSellExperiment, displayCrossSellRV]);

  useEffect(() => {
    if (
      displayCrossSellRV &&
      rewardsAccounts.length > 0 &&
      isRecentlyViewedV1Experiment &&
      ((!matchesMobile && recentlyViewedDesktopP0Variant !== CONTROL) ||
        (matchesMobile && recentlyViewedMobileP0Variant !== CONTROL))
    ) {
      const earnMultiplier = (account: RewardsAccount) =>
        account.earn.hotelsMultiplier ?? -1;
      const account = rewardsAccounts.sort((prev, current) => {
        return earnMultiplier(current) - earnMultiplier(prev);
      })[0];
      const fetchRecentViewsAndSearches = async () => {
        const props: RecentlyViewedAndSearchedProps | false =
          await fetchRecentlyViewedAndSearched(
            account,
            recentlyViewedDesktopP0Variant,
            isRecentlyViewedFlightsV2,
            isRecentlyViewedCarsV2
          );
        if (props) {
          setRecentlyViewedAndSearchedProps(props);
          setRecentlyViewedLoaded(true);
        }
      };
      const properties: ProductFeedLoadingProperties = {
        feed_placement: "homepage",
        feed_type: "recently_viewed",
      };
      trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
      fetchRecentViewsAndSearches();
    }
  }, [
    rewardsAccounts,
    isRecentlyViewedV1Experiment,
    recentlyViewedDesktopP0Variant,
    recentlyViewedMobileP0Variant,
    matchesMobile,
    displayCrossSellRV,
    isRecentlyViewedFlightsV2,
    isRecentlyViewedCarsV2,
  ]);

  useEffect(() => {
    if (
      isOnePortalExperimentEnabled &&
      fetchRewardsAccountsCallState === CallState.Failed &&
      isBusinessEligible
    ) {
      window.location.assign(config.corporatePortalUrl!);
    }
  }, [
    fetchRewardsAccountsCallState,
    isOnePortalExperimentEnabled,
    isBusinessEligible,
  ]);

  const isFetchingRewards = useMemo(() => {
    return (
      fetchRewardsAccountsCallState === CallState.NotCalled ||
      fetchRewardsAccountsCallState === CallState.InProcess
    );
  }, [fetchRewardsAccountsCallState]);

  const isHotelsOrStaysPath = (path: string) => {
    return path == PATH_HOTELS || path == PATH_STAYS;
  };

  return (
    <main className={clsx("main-body", classes.main, className)}>
      {isInMaintenance ? (
        <Maintenance />
      ) : (
        <Grid className={classes.content}>
          <Switch>
            <Route
              exact
              path={PATH_FLIGHTS_SEARCH}
              render={(browserRouterProps: RouteComponentProps) =>
                flightsMaintenance ? (
                  <Maintenance />
                ) : matchesMobile ? (
                  <FlightsModule
                    crossSellLoaded={false}
                    language={language}
                    experiments={expState}
                    recentlyViewedLoaded={false}
                    annualTravelCreditLoaded={false}
                    {...browserRouterProps}
                  />
                ) : (
                  <Redirect to={PATH_FLIGHTS} />
                )
              }
            />
            <Route
              path={PATH_FLIGHTS}
              render={(browserRouterProps: RouteComponentProps) =>
                flightsMaintenance ? (
                  <Maintenance />
                ) : !showFlightsFunnel ? (
                  <Redirect to={getRedirectPath()} />
                ) : matchesMobile &&
                  browserRouterProps.history.location.pathname ===
                    PATH_FLIGHTS &&
                  mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                  <>
                    <MobileLandingPage
                      type={LandingPageType.FLIGHTS}
                      rewardsAccounts={rewardsAccounts}
                      recentlyViewedAndSearchedProps={
                        recentlyViewedAndSearchedProps
                      }
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                    />
                    {isHotelCrossSellExperiment && crossSellProps ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {showUserFlightPreferencesBanner && (
                      <UserPreferencesBanner
                        type="flight"
                        variant="fixed"
                        isMobile
                        userPreferencesCallState={CallState.Success}
                        userHasSetPreferences={false}
                        shouldApplyUserPreferences={false}
                        setShouldApplyUserPreferences={() => {}}
                        bannerProfileCTA={
                          <Link
                            to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
                            className="profile-cta"
                          >
                            Add travel preferences
                          </Link>
                        }
                        modalProfileCTA={
                          <Link
                            to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
                            className="info-modal-primary-cta"
                          >
                            Add travel preferences
                          </Link>
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    <FlightsModule
                      language={language}
                      experiments={expState}
                      crossSellLoaded={crossSellLoaded}
                      recentlyViewedLoaded={recentlyViewedLoaded}
                      annualTravelCreditLoaded={showAnnualTravelCredit}
                      {...browserRouterProps}
                    />
                    {showAnnualTravelCredit &&
                      browserRouterProps.history.location.pathname ===
                        PATH_FLIGHTS && (
                        <HomepageAnnualTravelCredit
                          firstName={sessionInfo.userInfo.firstName}
                          rewardsAccounts={rewardsAccounts}
                          walletDetails={walletDetails}
                          isAnnualTravelCreditsExperiment={
                            isAnnualTravelCreditsExperiment
                          }
                        />
                      )}
                    {isTravelSaleEnabled &&
                      browserRouterProps.history.location.pathname ===
                        PATH_FLIGHTS && (
                        <HomepageTravelSale
                          active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
                          offers={walletDetails?.offers || []}
                        />
                      )}
                    {isHotelCrossSellExperiment &&
                    showCrossSellV3UI &&
                    !!crossSellProps &&
                    browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {!!recentlyViewedAndSearchedProps &&
                    recentlyViewedDesktopP0Variant !== CONTROL &&
                    rewardsAccounts &&
                    browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS ? (
                      <RecentlyViewedAndSearched
                        {...recentlyViewedAndSearchedProps}
                      />
                    ) : null}
                    {isHotelCrossSellExperiment &&
                    !showCrossSellV3UI &&
                    !!crossSellProps &&
                    browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                )
              }
            />
            <Route
              exact
              path={[PATH_HOTELS_SEARCH, PATH_STAYS_SEARCH]}
              render={(browserRouterProps: RouteComponentProps) =>
                hotelsMaintenance ? (
                  <Maintenance />
                ) : matchesMobile ? (
                  <HotelsModule
                    language={language}
                    {...browserRouterProps}
                    experiments={expState}
                    crossSellLoaded={false}
                    recentlyViewedLoaded={false}
                    annualTravelCreditLoaded={false}
                  />
                ) : (
                  <Redirect to={PATH_HOTELS} />
                )
              }
            />
            <Route
              path={[PATH_HOTELS, PATH_STAYS]}
              render={(browserRouterProps: RouteComponentProps) =>
                hotelsMaintenance ? (
                  <Maintenance />
                ) : !showHotelsFunnel ? (
                  <Redirect to={getRedirectPath()} />
                ) : matchesMobile &&
                  isHotelsOrStaysPath(
                    browserRouterProps.history.location.pathname
                  ) &&
                  mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                  <>
                    <MobileLandingPage
                      type={
                        isHotelsOrStaysPath(
                          browserRouterProps.history.location.pathname
                        )
                          ? staysHomepageEnabled
                            ? LandingPageType.STAYS
                            : LandingPageType.HOTELS
                          : LandingPageType.HOTELS
                      }
                      rewardsAccounts={rewardsAccounts}
                      recentlyViewedAndSearchedProps={
                        recentlyViewedAndSearchedProps
                      }
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                    />
                    {isHotelCrossSellExperiment && crossSellProps ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {showUserHotelPreferencesBanner && (
                      <UserPreferencesBanner
                        type="hotel"
                        variant="fixed"
                        isMobile
                        userPreferencesCallState={CallState.Success}
                        userHasSetPreferences={false}
                        shouldApplyUserPreferences={false}
                        setShouldApplyUserPreferences={() => {}}
                        bannerProfileCTA={
                          <Link
                            to={`${PATH_CUSTOMER_PROFILE}?section=hotel-preferences`}
                            className="profile-cta"
                          >
                            Add travel preferences
                          </Link>
                        }
                        modalProfileCTA={
                          <Link
                            to={`${PATH_CUSTOMER_PROFILE}?section=hotel-preferences`}
                            className="info-modal-primary-cta"
                          >
                            Add travel preferences
                          </Link>
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    <HotelsModule
                      language={language}
                      crossSellLoaded={crossSellLoaded}
                      recentlyViewedLoaded={recentlyViewedLoaded}
                      annualTravelCreditLoaded={showAnnualTravelCredit}
                      {...browserRouterProps}
                      experiments={expState}
                    />
                    {showAnnualTravelCredit &&
                      isHotelsOrStaysPath(
                        browserRouterProps.history.location.pathname
                      ) && (
                        <HomepageAnnualTravelCredit
                          firstName={sessionInfo.userInfo.firstName}
                          rewardsAccounts={rewardsAccounts}
                          walletDetails={walletDetails}
                          isAnnualTravelCreditsExperiment={
                            isAnnualTravelCreditsExperiment
                          }
                        />
                      )}
                    {isTravelSaleEnabled &&
                      isHotelsOrStaysPath(
                        browserRouterProps.history.location.pathname
                      ) && (
                        <HomepageTravelSale
                          active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
                          offers={walletDetails?.offers || []}
                        />
                      )}
                    {isHotelCrossSellExperiment &&
                    showCrossSellV3UI &&
                    crossSellProps &&
                    isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {!!recentlyViewedAndSearchedProps &&
                    recentlyViewedDesktopP0Variant !== CONTROL &&
                    rewardsAccounts &&
                    isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) ? (
                      <RecentlyViewedAndSearched
                        {...recentlyViewedAndSearchedProps}
                      />
                    ) : null}
                    {isHotelCrossSellExperiment &&
                    !showCrossSellV3UI &&
                    crossSellProps &&
                    isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                )
              }
            />
            <Route
              exact
              path={PATH_CARS_SEARCH}
              render={(browserRouterProps: RouteComponentProps) =>
                carsMaintenance ? (
                  <Maintenance />
                ) : matchesMobile ? (
                  <CarsModule
                    language={language}
                    {...browserRouterProps}
                    experiments={expState}
                    crossSellLoaded={false}
                    recentlyViewedLoaded={false}
                    annualTravelCreditLoaded={false}
                  />
                ) : (
                  <Redirect to={PATH_CARS} />
                )
              }
            />
            <Route
              path={PATH_CARS}
              render={(browserRouterProps: RouteComponentProps) =>
                carsMaintenance ? (
                  <Maintenance />
                ) : !showCarsFunnel ? (
                  <Redirect to={getRedirectPath()} />
                ) : matchesMobile &&
                  browserRouterProps.history.location.pathname === PATH_CARS &&
                  mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                  <>
                    <MobileLandingPage
                      type={LandingPageType.CARS}
                      rewardsAccounts={rewardsAccounts}
                      recentlyViewedAndSearchedProps={
                        recentlyViewedAndSearchedProps
                      }
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                    />
                    {isHotelCrossSellExperiment && crossSellProps ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                ) : (
                  <>
                    <CarsModule
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                      crossSellLoaded={crossSellLoaded}
                      recentlyViewedLoaded={recentlyViewedLoaded}
                      annualTravelCreditLoaded={showAnnualTravelCredit}
                    />
                    {showAnnualTravelCredit &&
                      browserRouterProps.history.location.pathname ===
                        PATH_CARS && (
                        <HomepageAnnualTravelCredit
                          firstName={sessionInfo.userInfo.firstName}
                          rewardsAccounts={rewardsAccounts}
                          walletDetails={walletDetails}
                          isAnnualTravelCreditsExperiment={
                            isAnnualTravelCreditsExperiment
                          }
                        />
                      )}
                    {isTravelSaleEnabled &&
                      browserRouterProps.history.location.pathname ===
                        PATH_CARS && (
                        <HomepageTravelSale
                          active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
                          offers={walletDetails?.offers || []}
                        />
                      )}
                    {isHotelCrossSellExperiment &&
                    showCrossSellV3UI &&
                    crossSellProps &&
                    browserRouterProps.history.location.pathname ===
                      PATH_CARS ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {!!recentlyViewedAndSearchedProps &&
                    recentlyViewedDesktopP0Variant !== CONTROL &&
                    rewardsAccounts &&
                    browserRouterProps.history.location.pathname ===
                      PATH_CARS ? (
                      <RecentlyViewedAndSearched
                        {...recentlyViewedAndSearchedProps}
                      />
                    ) : null}
                    {isHotelCrossSellExperiment &&
                    !showCrossSellV3UI &&
                    crossSellProps &&
                    browserRouterProps.history.location.pathname ===
                      PATH_CARS ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                )
              }
            />
            <Route
              exact
              path={[
                PATH_PREMIER_COLLECTION_SEARCH,
                PATH_VACATION_RENTALS_SEARCH,
              ]}
              render={(browserRouterProps: RouteComponentProps) => {
                if (
                  showPremiumStaysFunnel &&
                  (isPremierCollectionEnabled ||
                    isLCForNonPremiumCardHoldersEnabled) &&
                  (matchesMobile // Adding a check on experiment be safe of users navigating to /premier-collection. Users who are on control should not see PC as PC search is not set up in control variant (this should be dated by experiment in prod but adding a check here for staging accts & to be safe )
                    ? mobileHomeScreenVariant !== CONTROL ||
                      isCorpTenant(config.TENANT)
                    : true)
                ) {
                  return (
                    <PremierCollectionModule
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                    />
                  );
                } else {
                  return isFetchingRewards ? (
                    <div>Loading...</div> // TODO: Add loading component
                  ) : (
                    <Redirect to={getRedirectPath()} />
                  );
                }
              }}
            />
            <Route
              path={PATH_PREMIER_COLLECTION}
              render={(browserRouterProps: RouteComponentProps) => {
                if (
                  showPremiumStaysFunnel &&
                  (isPremierCollectionEnabled ||
                    isLCForNonPremiumCardHoldersEnabled) &&
                  (matchesMobile // Adding a check on experiment be safe of users navigating to /premier-collection. Users who are on control should not see PC as PC search is not set up in control variant (this should be dated by experiment in prod but adding a check here for staging accts & to be safe )
                    ? mobileHomeScreenVariant !== CONTROL ||
                      isCorpTenant(config.TENANT)
                    : true)
                ) {
                  if (
                    matchesMobile &&
                    browserRouterProps.history.location.pathname ===
                      PATH_PREMIER_COLLECTION &&
                    mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3
                  ) {
                    return (
                      <MobileLandingPage
                        type={LandingPageType.PREMIUM_STAYS}
                        rewardsAccounts={rewardsAccounts}
                        recentlyViewedAndSearchedProps={
                          recentlyViewedAndSearchedProps
                        }
                        fetchRewardsAccountsCallState={
                          fetchRewardsAccountsCallState
                        }
                      />
                    );
                  } else {
                    return (
                      <>
                        <PremierCollectionModule
                          language={language}
                          {...browserRouterProps}
                          experiments={expState}
                        />
                        {showAnnualTravelCredit &&
                          browserRouterProps.history.location.pathname ===
                            PATH_PREMIER_COLLECTION && (
                            <HomepageAnnualTravelCredit
                              firstName={sessionInfo.userInfo.firstName}
                              rewardsAccounts={rewardsAccounts}
                              walletDetails={walletDetails}
                              isAnnualTravelCreditsExperiment={
                                isAnnualTravelCreditsExperiment
                              }
                              showBanner={false}
                            />
                          )}
                      </>
                    );
                  }
                } else {
                  return isFetchingRewards ? (
                    <div>Loading...</div> // TODO: Add loading component
                  ) : (
                    ([CallState.Success, CallState.Failed].includes(
                      expState.callState
                    ) ||
                      expState.experiments.length > 0) && (
                      <Redirect to={getRedirectPath()} />
                    )
                  );
                }
              }}
            />
            <Route
              path={PATH_TRIPS}
              render={(browserRouterProps: RouteComponentProps) =>
                tripsMaintenance ? (
                  <Maintenance />
                ) : (
                  <TripsModule
                    experiments={expState}
                    language={language}
                    {...browserRouterProps}
                  />
                )
              }
            />
            <Route
              path={[
                PATH_TERMS,
                PATH_TERMS_OF_SERVICE,
                PATH_SITE_TERMS_OF_USE,
                PATH_CORP_FAQ,
              ]}
              render={(browserRouterProps: RouteComponentProps) => (
                <TermsModule
                  language={language}
                  experiments={expState}
                  authenticated={!notAuthenticated}
                  {...browserRouterProps}
                />
              )}
            />
            <Route
              path={PATH_EXCHANGE}
              render={(browserRouterProps: RouteComponentProps) => (
                <ExchangeModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />
            <Route
              exact
              path={PATH_TRAVEL_SALE}
              render={(browserRouterProps: RouteComponentProps) => {
                if (isTravelSaleEnabled) {
                  return (
                    <TravelWalletModule
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                    />
                  );
                } else {
                  return isFetchingRewards ? (
                    <div>Loading...</div> // TODO: Add loading component
                  ) : (
                    <Redirect to={PATH_HOTELS} />
                  );
                }
              }}
            />
            <Route
              path={PATH_TRAVEL_WALLET}
              render={(browserRouterProps: RouteComponentProps) => (
                <TravelWalletModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />

            <Route
              path={PATH_CUSTOMER_PROFILE}
              render={(browserRouterProps: RouteComponentProps) => (
                <CustomerProfileModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />

            <Route
              exact
              path={PATH_PACKAGES_SEARCH}
              render={(browserRouterProps: RouteComponentProps) =>
                isPackagesExperiment ? (
                  matchesMobile ? (
                    <PackagesModule
                      experiments={expState}
                      language={language}
                      {...browserRouterProps}
                    />
                  ) : (
                    <Redirect to={PATH_PACKAGES} />
                  )
                ) : (
                  <Redirect to={getRedirectPath()} />
                )
              }
            />
            <Route
              path={PATH_PACKAGES}
              render={(browserRouterProps: RouteComponentProps) =>
                isPackagesExperiment ? (
                  matchesMobile &&
                  browserRouterProps.history.location.pathname ===
                    PATH_PACKAGES ? (
                    <MobileLandingPage
                      type={LandingPageType.PACKAGES}
                      rewardsAccounts={rewardsAccounts}
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                    />
                  ) : (
                    <PackagesModule
                      experiments={expState}
                      language={language}
                      {...browserRouterProps}
                    />
                  )
                ) : (
                  ([CallState.Success, CallState.Failed].includes(
                    expState.callState
                  ) ||
                    expState.experiments.length > 0) && (
                    <Redirect to={getRedirectPath()} />
                  )
                )
              }
            />

            <Route path={PATH_CORP_NOT_LIVE} render={() => <PortalNotLive />} />
            <Route
              path={PATH_CORP_ACCESS_DENIED}
              render={() => <AccessDenied />}
            />
            <Route
              path={PATH_CORP_ACCESS_DENIED_NOT_BETA}
              render={() => <AccessDeniedNotInBeta />}
            />

            <Route path="*">
              {/* TODO: once we have started working on the new mobile search workflow changes, this component will likely be relocated
            so that RewardsBanner can be added to the mobile home page; see https://hopper-jira.atlassian.net/browse/BP-725 */}
              {matchesMobile &&
              mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                <>
                  <MobileLandingPage
                    type={getLandingPageType()}
                    rewardsAccounts={rewardsAccounts}
                    recentlyViewedAndSearchedProps={
                      recentlyViewedAndSearchedProps
                    }
                    fetchRewardsAccountsCallState={
                      fetchRewardsAccountsCallState
                    }
                  />
                  {isHotelCrossSellExperiment && crossSellProps ? (
                    <HomepageHotelCrossSell {...crossSellProps} />
                  ) : null}
                </>
              ) : (
                <Redirect to={getRedirectPath()} />
              )}
            </Route>
          </Switch>
          <PostBookingOfferModal
            history={history}
            showOnPaths={[
              { path: PATH_HOTELS, screen: "hotel_homepage" },
              { path: PATH_FLIGHTS, screen: "air_homepage" },
              { path: PATH_TRIPS, screen: "my_trips_homepage" },
              { path: PATH_HOME, screen: "hotel_homepage" },
            ]}
            getPostBookingOffer={() => {
              return getPostBookingOffer({
                referenceDateTime: dayjs().format(),
              });
            }}
            showRewardsMerch={showPostBookingPayByRewardsMerch}
            newAirCopy={isDisruptionAirCopyAvailable}
            isPostBookingOffersAvailable={isPostBookingOffersAvailable}
            trackEvent={trackEvent}
          />
          {isCaponeTenant(config.TENANT) && (
            <CorporateSwitchModal
              rewards={rewardsAccounts}
              isFetchingRewards={isFetchingRewards}
            />
          )}
        </Grid>
      )}
    </main>
  );
};

const getLandingScreen = (): LandingScreen => {
  const path = window.location.pathname;
  if (path.includes("flights")) {
    return path.includes("search") ? "flight_search" : "flight_shop";
  }
  if (path.includes("cars")) {
    return path.includes("search") ? "cars_search" : "cars_list";
  }
  if (path.includes("hotels")) {
    return path.includes("search") ? "hotel_search" : "hotel_list";
  }
  if (path.includes("trips")) {
    return "my_trips";
  }
  if (path.includes("exchange")) {
    return "exchange";
  }
  if (path.includes("travel-wallet")) {
    return "travel_wallet";
  }
  return "hotel_search";
};

export default Body;
